import React, { Fragment, ReactEventHandler } from "react";
import { Link, Redirect } from "react-router-dom";
import EditLink from "./EditLink";
import "./Tag.css";
import "./EditTag.css";
import Placeholder from "react-bootstrap/Placeholder"
import Spinner from "react-bootstrap/Spinner"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Alert from "react-bootstrap/Alert"
import Modal from "react-bootstrap/Modal"
import TextField from '@material-ui/core/TextField';
import Icon from "@mdi/react"
import { mdiTrashCanOutline } from '@mdi/js';
import { API_URL } from "../App";

interface link {
	type?: string | null,
	name: string,
	username?: string | null,
	user_prefix?: '@' | '/' | 'u/' | '',
	link: string,
	mdi_icon?: string | null,
	key?: string | number
}

interface tag {
	tag_id: string
	name: string,
	links: Array<link>,
	mod_token?: string
}

export default class EditTag extends React.Component {
	props: any
	state: {
		loading: boolean,
		error: boolean,
		success: boolean,
		unauthorized: boolean,
		notFound: boolean,
		tagId: string,
		tag: tag,
	}
	newTag: tag
	dummyLink: link
	constructor(props: any) {
		super(props);
		this.props = props;
		this.newTag = {
			tag_id: this.props.match.params.tagId,
			name: "",
			links: []
		}
		this.state = {
			loading: true,
			error: false,
			success: false,
			unauthorized: false,
			notFound: false,
			tagId: this.props.match.params.tagId,
			tag: this.newTag
		}
		this.dummyLink = {
			name: "",
			link: ""
		}
		this.sendEdit = this.sendEdit.bind(this);
	}
	sendEdit() {
		this.setState({ unauthorized: false })
		var that = this
		fetch(API_URL + `/tag/${this.state.tagId}`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(that.state.tag)
		}).then(r => {
			console.log(r)
			if (r.status === 200) {
			}
			if (r.status === 401) {
			}
			switch (r.status) {
				case 200:
					that.setState({ success: true })
					break;
				case 401:
					that.setState({ error: true, unauthorized: true })
					break;
				case 404:
					that.setState({ error: true, notFound: true })
					break;
				default:
					that.setState({ error: true })
					break;
			}
		}).catch((e) => {
		})
	}
	componentDidMount() {
		var that = this;
		fetch(API_URL+`/tag/${this.state.tagId}`)
			.then(r => {
				switch (r.status) {
					case 200:
						break;
					case 404:
						that.setState({ loading: false, error: true, notFound: true })
						break;
					default:
						break;
				}
				return r.json()
			})
			.then(data => {
				let tmpTag: tag = data
				tmpTag.links.forEach((link) => {
					link.key = Math.random().toString()
				})
				that.setState({ loading: false, tag: tmpTag })
				that.newTag = tmpTag
			})
			.catch((e) => {
				that.setState({ error: true, loading: false })
			})
		;
	}
	render() {
		if (this.state.loading) {
			return (
				<div className="Tag-div placeholder-glow">
					<div className="Tag-button-div">
						<Link to={`/tag/${this.state.tagId}`}>
							<Button
								variant="outline-danger"
								size="sm"
							>Cancel</Button>
						</Link>{" "}
						<Button
							className="placeholder"
							variant="primary"
							size="sm"
							onClick={this.sendEdit}
						>Save</Button>
					</div>
					<h1>Edit Tag</h1>
					<span className="placeholder col-2"></span><br /><br />
					<span className="placeholder col-12" style={{ height: '38px' }}></span>
					<span className="placeholder col-2"></span><br /><br />
					<span className="placeholder col-12" style={{ height: '38px' }}></span>
					<hr />
					<h4 className="placeholder">Links</h4>
				</div>
			)
		}
		if (this.state.error && !this.state.unauthorized) {
			return (
				<div className="Tag-div">
					<h1>404 - Tag not found</h1>
				</div>
			)
		}
		try {
			let edLinks: Array<any> = this.state.tag.links.map((el, i) => {
				return (
					<div key={el.key || Math.random().toString()}>
						<Row>
							<EditLink
								linkText={ el.name || "" }
								link={ el.link || "" }
								id={ "link-" + i }
								onChangeName={(e: any) => {
									const { links } = this.state.tag
									links.splice(i, 1, {...this.state.tag.links[i], name: e.target.value || ""})
									this.setState({
										tag: {
											...this.state.tag,
											links: [...links]
										}
									},()=>{console.log(this.state.tag.links[i])})
								}}
								onChangeLink={(e: any) => {
									const { links } = this.state.tag
									links.splice(i, 1, {...this.state.tag.links[i], link: e.target.value || ""})
									this.setState({
										tag: {
											...this.state.tag,
											links: [...links]
										}
									})
								}}
							/>
							<div className="col-sm-auto align-self-end d-none d-sm-block">
								<Button
									variant="outline-danger"
									onClick={() => {
										// document.getElementById(`links-div-${i}`)?.remove()
										const { links } = this.state.tag
										links.splice(i, 1)
										this.setState({
											tag: {
												...this.state.tag,
												links: [...links]
											}
										},()=>{console.log(JSON.stringify(this.state.tag.links))})
									}}
									className="d-flex justify-content-center align-content-between"
									// hidden
								><Icon path={mdiTrashCanOutline}
									size={1}
									horizontal
								/></Button>
							</div>
							<div className="d-grid gap-3 d-block d-sm-none EditTag-remove-link-div-small">
								<Button
									variant="outline-danger"
									size="sm"
									onClick={() => {
										// document.getElementById(`links-div-${i}`)?.remove()
										const { links } = this.state.tag
										links.splice(i, 1)
										this.setState({
											tag: {
												...this.state.tag,
												links: [...links]
											}
										},()=>{console.log(JSON.stringify(this.state.tag.links))})
									}}
									className="d-flex justify-content-center align-content-between"
									// hidden
								><Icon path={mdiTrashCanOutline}
									size={0.8}
									horizontal
									style={{ display: "none" }}
								/> Remove link</Button>
							</div>
						</Row>
						{ (this.state.tag.links.length > i+1) ? <hr /> : null }
					</div>
				)
			})
			return (
				<div className="Tag-div">
					<div className="Tag-button-div">
						<Link to={`/tag/${this.state.tagId}`}>
							<Button
								variant="outline-danger"
								size="sm"
							>Cancel</Button>
						</Link>{" "}
						<Button
							variant="primary"
							size="sm"
							onClick={this.sendEdit}
						>Save</Button>
					</div>
					<h1>Edit Tag</h1>
					{this.state.unauthorized ?
						<Alert variant="danger" onClose={() => this.setState({ unauthorized: false })} dismissible>
							<Alert.Heading>Wrong edit token!</Alert.Heading>
							<p>You have provided the wrong edit token. Try again!</p>
						</Alert>
						: null
					}
					<Modal show={this.state.success} onHide={() => { this.setState({ success: false }) }}>
						<Modal.Header closeButton>
							<Modal.Title>Success!</Modal.Title>
						</Modal.Header>
						<Modal.Body>You successfully updated this tag!</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={() => {
								this.setState({ success: false })
							}}>
								Close
							</Button>
							<Link to={`/tag/${this.state.tagId}`}>
								<Button variant="primary">
									Back
								</Button>
							</Link>
						</Modal.Footer>
					</Modal>
					<form>
						<Form.Group controlId="edit-token">
							<Form.Label>
								Edit token
							</Form.Label>
							<Form.Control
								type="text"
								onChange={
									(e) => {
										this.setState({
											tag: {
												...this.state.tag,
												mod_token: e.target.value || ""
											}
										})
									}
								}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>
								Tag name
							</Form.Label>
							<Form.Control
								type="text"
								defaultValue={this.state.tag.name || ""}
								id="tag-name"
								onChange={
									(e) => {
										this.setState({
											tag: {
												...this.state.tag,
												name: e.target.value || ""
											}
										})
									}
								}
							/>
						</Form.Group>
						{/* <TextField id="standard-basic" label="Edit token"
							type="text"
							fullWidth
							onChange={
								(e) => {
									this.setState({
										tag: {
											...this.state.tag,
											mod_token: e.target.value || ""
										}
									})
								}
							}
						/><br /><br />
						<TextField id="standard-basic" label="Tag name"
							defaultValue={this.state.tag.name || ""}
							//id="tag-name"
							fullWidth
							onChange={
								(e) => {
									this.setState({
										tag: {
											...this.state.tag,
											name: e.target.value || ""
										}
									})
								}
							}
						/> */}
						<hr />
						<h2>Links</h2>
						<div className="Tag-edit-links">
							{edLinks}
						</div>
						<div className="d-grid gap-3">
							<Button
								variant="outline-danger"
								size="sm"
								onClick={() => {
									const { links } = this.state.tag
									links.splice(-1, 1)
									this.setState({
										tag: {
											...this.state.tag,
											links: [...links]
										}
									},()=>{console.log(JSON.stringify(this.state.tag.links))})
								}}
								hidden
							>Remove link</Button>
							<Button
								variant="outline-primary"
								size="sm"
								onClick={() => {
									this.setState({
										tag: {
											...this.state.tag,
											links: [...this.state.tag.links, {name: "", link: "", key: Math.random.toString()}]
										}
									})
								}}
							>Add link</Button>
						</div>
					</form>
				</div>
			)
		} catch (e) {
			console.log("Error: ", e)
			// return <Redirect to="/" />
		}
	}
}