import React, { Fragment, ReactEventHandler } from "react";
import { Link, Redirect } from "react-router-dom";
import EditLink from "./EditLink";
import "./Tag.css";
import Placeholder from "react-bootstrap/Placeholder"
import Spinner from "react-bootstrap/Spinner"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row";
import { API_URL } from "../App";

interface link {
	type?: string | null,
	name: string,
	username?: string | null,
	user_prefix?: '@' | '/' | 'u/' | '',
	link: string,
	mdi_icon?: string | null
}

interface tag {
	tag_id: string
	name: string,
	links: Array<link>,
	mod_token?: string
}

export default class Tag extends React.Component {
	props: any
	state: {
		loading: boolean,
		error: boolean,
		notFound: boolean,
		tagId: string,
		tag: tag | null,
	}
	constructor(props: any) {
		super(props);
		this.props = props;
		this.state = {
			loading: true,
			error: false,
			notFound: false,
			tagId: this.props.match.params.tagId,
			tag: null
		}
	}
	componentDidMount() {
		var that = this;
		fetch(API_URL+`/tag/${this.state.tagId}`)
			.then(r => {
				if (r.status === 404) {
					that.setState({ loading: false, error: true, notFound: true })
				}
				return r.json()
			})
			.then(data => {
				that.setState({ loading: false, tag: data })
			})
			.catch((e) => {
				that.setState({error: true, loading: false})
			})
		;
	}
	render() {
		if (this.state.loading) {
			return (
				<div className="Tag-div placeholder-glow">
					<Button
						variant="outline-primary"
						size="sm"
						className="Tag-edit-button disabled placeholder"
						style={{ width: '41px' }}
						aria-hidden="true"
						></Button><br />
					<h1 className="Tag-name"><span className="placeholder">Taa name</span></h1>
					<div className="d-grid gap-2 placeholder">
						<Button href="" target="_BLANK" className="disabled placeholder" aria-hidden="true"></Button>
					</div>
				</div>
			)
		}
		if (this.state.error) {
			if (this.state.notFound) {
				return (
					<div className="Tag-div">
						<h1>404 - Tag not found</h1>
					</div>
				)
			}
			return (
				<div className="Tag-div">
					<h1>Error</h1>
				</div>
			)
		}
		try {
			let links: Array<any> = [];
			this.state.tag?.links.forEach((e) => {
				links.push(
					<Button href={e.link} target="_BLANK" variant="outline-primary">{e.name}</Button>
				)
			})
			return (
				<div className="Tag-div">
					<Link to={`/tag/${this.state.tagId}/edit`}>
						<Button
							variant="outline-primary"
							size="sm"
							className="Tag-edit-button"
						>Edit</Button>
					</Link>
					<br />
					<h1 className="Tag-name">{this.state.tag?.name}</h1>
					<div className="d-grid gap-2">
						{links}
					</div>
				</div>
			)
		} catch (e) {
			return <Redirect to="/" />
		}
	}
}
