import React, { Fragment } from "react";
import { ReactEventHandler } from "react";
import "./EditLink.css";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

type EditLinkProps = {
	linkText: string,
	link: string,
	id: string,
	onChangeName: ReactEventHandler,
	onChangeLink: ReactEventHandler,
	this?: any
}

export default function EditLink(props: EditLinkProps) {
	return (
		<Fragment>
			<Form.Group as={Col} className="EditLink-col">
				<Form.Label>Link text</Form.Label>
				<Form.Control
					type="text"
					defaultValue={props.linkText || ""}
					id={props.id + "-name"}
					onChange={props.onChangeName}
				/>
			</Form.Group>
			<Form.Group as={Col} className="EditLink-col">
				<Form.Label>Link URL</Form.Label>
				<Form.Control
					type="text"
					defaultValue={props.link || ""}
					id={props.id + "-link"}
					onChange={props.onChangeLink}
				/>
			</Form.Group>
		</Fragment>
	)
	// return (
	// 	<div className="EditLink-div">
	// 		<label>
	// 			Link text:<br />
	// 			<input type="text" defaultValue={props.linkText || ""} id={props.id + "-name"} onChange={props.onChangeName} />
	// 		</label><br />
	// 		<label>
	// 			Link URL:<br />
	// 			<input type="text" defaultValue={props.link || ""} id={props.id + "-link"} onChange={props.onChangeLink} />
	// 		</label>
	// 	</div>
	// )
}
