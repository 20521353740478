import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from 'react-router-dom';
import Tag from './components/Tag';
import EditTag from './components/EditTag';
import Container from 'react-bootstrap/Container';
import AdminCreateTag from './components/AdminCreateTag';

export const API_URL = process.env.REACT_APP_API_URL || "http://10.200.0.222:8080"// || "https://tag-api.herrblauzahn.de"

function App() {
	return (
		<Router>
			<Container>
				<Switch>
					<Route path="/tag/:tagId/edit" component={EditTag} />
					<Route path="/tag/:tagId" component={Tag} />
					<Route path="/admin/createtag" component={AdminCreateTag} />
					<Route path="/">
						<>
							<h1>Start</h1>
						</>
					</Route>
				</Switch>
			</Container>
		</Router>
	);
}

export default App;
