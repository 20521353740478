import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import "./Tag.css";
import { API_URL } from "../App";

export default function AdminCreateTag() {

	// let API_KEY = ""
	const [API_KEY, setAPI_KEY] = useState("")
	const [unauthenticated, setUnauthenticated] = useState(false)
	const [gotTag, setGotTag] = useState(false)
	const [tagId, setTagId] = useState("")
	const [editToken, setEditToken] = useState("")

	return (
		<div className="Tag-div">
			<h1 style={{marginBottom: '24px'}}>Create Tag</h1>
			<Row>
				<Col>
					<Form.Label htmlFor="api-key" hidden>API Key</Form.Label>
					<InputGroup className="mb-3">
						<InputGroup.Text id="basic-addon3">
							API key
						</InputGroup.Text>
						<FormControl id="api-key" type="password" aria-describedby="basic-addon3" onChange={(e) => {
							setAPI_KEY(e.target.value)
						}} />
					</InputGroup>
				</Col>
				<Col className="col-sm-auto">
					<Button variant="outline-primary" onClick={() => {
						fetch(API_URL + `/admin/createtag`, {
							method: "POST",
							headers: {
								Authorization: `Bearer ${ API_KEY }`
							}
						}).then((r) => {
							switch (r.status) {
								case 401:
									setUnauthenticated(true)
									break;
								default:
									setUnauthenticated(false)
									break;
								}
								return r.json()
							}).then((r) => {
								if (!unauthenticated) {
									setTagId(r.tag_id)
									setEditToken(r.mod_token)
									setGotTag(true)
							}
						}).catch((e)=>{})
					}}>Create Tag</Button>
				</Col>
			</Row>
			{ unauthenticated ?
				<Alert variant="danger" onClose={() => setUnauthenticated(false)} dismissible>
				<Alert.Heading>Wrong API Token!</Alert.Heading>
					Try Again!
				</Alert>
			: null }
			{ gotTag ?
				<div>
					<table>
						<tr>
							<td>Tag ID:&nbsp;</td>
							<td><code>{tagId}</code></td>
						</tr>
						<tr>
							<td>Edit Token:&nbsp;</td>
							<td><code>{editToken}</code></td>
						</tr>
					</table>
				</div>
			: null }
		</div>
	)
}